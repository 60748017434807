import React from 'react';
import { Inline, Text, Rating } from '@treatwell/ui';
import { VenueReviewResponse } from 'js/pages/VenuePage/VenueReviewsSection/VenueReviews/VenueReviewResponse';
import { VenueReviewTreatments } from 'js/pages/VenuePage/VenueReviewsSection/VenueReviews/VenueReviewTreatments';
import { VerifiedReviewIcon } from 'js/pages/VenuePage/VenueReviewsSection/VenueReviews/VerifiedReviewsBlock/VerifiedReviewIcon';
import styles from './VenueReview.module.css';

interface Props {
  review: {
    rating: number;
    content: string;
    employeeDescription: string;
    treatmentNames?: string[];
    author: string;
    isVerified: boolean;
    created: string;
  };
  response: {
    content: string;
    visibilityToggleLabels: {
      isVisible: string;
      isHidden: string;
    };
  };
  reportLink: {
    label: string;
    uri: string;
    isVisible: boolean;
  };
  showAllTreatmentsLabel: string;
  showTreatmentNames?: boolean;
}

export function VenueReview({
  review,
  response,
  reportLink,
  showAllTreatmentsLabel,
  showTreatmentNames,
}: Props): React.ReactElement {
  return (
    <div className={styles.container}>
      <div className={styles.stars}>
        <Rating rating={review.rating} size={24} showRatingValue={false} />
      </div>

      {review.content && (
        <div className={styles.content}>
          <Text>{review.content}</Text>
        </div>
      )}

      {(review.employeeDescription ||
        (review.treatmentNames && review.treatmentNames.length > 0)) && (
        <div className={styles.treatmentMetadata}>
          <Inline space="xs" align="center" wrap>
            {review.employeeDescription && (
              <Text type="caption" className={styles.employeeDescription}>
                {review.employeeDescription}
              </Text>
            )}

            {review.employeeDescription &&
              review.treatmentNames &&
              review.treatmentNames?.length > 0 &&
              showTreatmentNames && <Text type="caption">•</Text>}

            {review.treatmentNames &&
              review.treatmentNames.length > 0 &&
              showTreatmentNames && (
                <VenueReviewTreatments
                  treatments={review.treatmentNames}
                  showAllLabel={showAllTreatmentsLabel}
                />
              )}
          </Inline>
        </div>
      )}

      <div className={styles.dash} />

      <div className={styles.reviewMetadata}>
        <Inline space="xs" align="center" wrap splitAfter={3}>
          <Text type="caption" className={styles.author}>
            {review.author}
          </Text>
          {review.isVerified && <VerifiedReviewIcon />}
          <Text type="caption">•</Text>
          <Text id="review-created" type="caption" suppressHydrationWarning>
            {review.created}
          </Text>
          {reportLink.isVisible && (
            <a href={reportLink.uri} className={styles.reportLink}>
              <Text type="caption">{reportLink.label}</Text>
            </a>
          )}
        </Inline>
      </div>

      {response.content && <VenueReviewResponse {...response} />}
    </div>
  );
}
