/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-string-refs */
/* eslint-disable react/no-find-dom-node */
import React, { createRef, RefObject } from 'react';

/**
 * Function to check if the component being wrapped has the method defined
 * @param  {Object}   scope    scope object where the wrapped component is set in its ref
 * @param  {String}   handler  method name to check
 * @return {Boolean}           check boolean
 */
const checkHandler = (scope: any, handler: string): boolean => {
  return (
    scope.wrappedComponentRef.current &&
    typeof scope.wrappedComponentRef.current[handler] === 'function'
  );
};

/**
 * Higher Order Component to enhance any Component with the clicking outside and window resize events
 * This enhancer will only add the events if the Component has the respective handlers
 * Based on https://github.com/kentor/react-click-outside
 * @param  {Object} Component  React class component to be enhanced
 * @return {Object}            React class component
 */
const EnhanceClickOutside = (Component: any): any => {
  const componentName = Component.displayName || Component.name;

  return class extends React.PureComponent {
    static displayName = `clickOutside-${componentName}`;

    domNodeRef: RefObject<HTMLDivElement | null>;

    wrappedComponentRef: RefObject<any>;

    constructor(props: any) {
      super(props);

      this.domNodeRef = createRef();
      this.wrappedComponentRef = createRef();
    }

    componentDidMount(): void {
      if (checkHandler(this, 'handleClickOutside')) {
        document.addEventListener('click', this.handleClickOutside, true);
      }

      if (checkHandler(this, 'handleWindowResize')) {
        window.addEventListener('resize', this.handleWindowResize, true);
      }
    }

    componentWillUnmount(): void {
      if (checkHandler(this, 'handleClickOutside')) {
        document.removeEventListener('click', this.handleClickOutside, true);
      }
      if (checkHandler(this, 'handleWindowResize')) {
        window.removeEventListener('resize', this.handleWindowResize, true);
      }
    }

    handleClickOutside = (evt: any): void => {
      const domNode = this.domNodeRef.current;

      if (!domNode) {
        return;
      }

      const isOutside =
        !domNode.contains(evt.target as Node) &&
        !(
          domNode.parentNode && domNode.parentNode.contains(evt.target as Node)
        );
      const isOutsideWindow = evt.target.tagName === 'HTML';

      if (isOutside && !isOutsideWindow) {
        this.wrappedComponentRef.current?.handleClickOutside(evt);
      }
    };

    handleWindowResize = (evt: any): void => {
      this.wrappedComponentRef.current?.handleWindowResize(evt);
    };

    render(): React.ReactNode {
      return (
        <div ref={this.domNodeRef}>
          <Component {...this.props} ref={this.wrappedComponentRef} />
        </div>
      );
    }
  };
};

export default EnhanceClickOutside;
