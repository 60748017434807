import React from 'react';
import clsx from 'clsx';
import styles from './TextInput.module.css';

export default class TextInput extends React.Component {
  static defaultProps = {
    showValidation: false,
    type: 'text',
  };

  state = {
    value: '',
    isValid: false,
  };

  onChange = event => {
    const value = event.target.value;
    let isValid = true;

    if (this.props.validationFunction) {
      isValid = this.props.validationFunction(value);
    }

    this.setState({
      value,
      isValid,
    });
  };

  render() {
    const showInvalid = this.props.showValidation && !this.state.isValid;
    const { placeholder, maxLength, positioningClassName, type } = this.props;

    return (
      <div className={positioningClassName}>
        <input
          className={clsx(styles.input, { [styles.invalid]: showInvalid })}
          type={type}
          placeholder={placeholder}
          maxLength={maxLength}
          value={this.state.value}
          onChange={this.onChange}
        />
      </div>
    );
  }
}
