import React from 'react';
import clsx from 'clsx';
import styles from './Popup.module.css';
import PageOverlay from '../PageOverlay';

import { KEY_CODE_ESCAPE } from '../constants/keycodes';
import { scrollElementTo } from '../helpers/animations';

const WINDOW_EDGES = 48;
const HEADER_SIZE = 48;
const MIN_WIDTH_SM = 520;

const getMaximumWindowSize = () => {
  return {
    width: window.innerWidth - WINDOW_EDGES * 2,
    height: window.innerHeight - WINDOW_EDGES * 2,
  };
};

class Popup extends React.PureComponent {
  constructor(props) {
    super(props);

    /**
     *  We can calculate and assign to "this.state"
     *  but we can't call "setState()" without a warning
     */
    if (this.hasMobileDimensions()) {
      this.state = {
        ...this.state,
        width: window.innerWidth,
      };
    } else {
      this.state = {
        ...this.state,
        ...this.calculateDimensions(),
      };
    }
  }

  /**
   *  These are always necessary on instantiation
   */
  state = {
    left: 0,
    top: 0,
    sidePadding: 0,
  };

  componentWillMount() {
    document.addEventListener('keydown', this.handleKeyPressed, false);

    if (this.props.sizeContent) {
      this.storeDocumentBody();
      document.body.style.overflow = 'hidden';

      window.addEventListener('resize', this.repositionDialog);

      this.repositionDialog();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyPressed, false);

    if (this.props.sizeContent) {
      window.removeEventListener('resize', this.repositionDialog);

      this.resetDocumentBody();
    }
  }

  storeDocumentBody() {
    /*
     *  There's no need to keep these in state; changes will call "render()"
     *  and we don't need the component to call "render()" when they are changed
     */
    this.documentBody = {
      style: { overflow: document.body.style.overflow },
      scrollTop: document.body.scrollTop,
    };
  }

  resetDocumentBody() {
    /*
     *  Destructure the stored values
     */
    const {
      style: { overflow },
      scrollTop,
    } = this.documentBody;

    /*
     *  Apply those stored values to the body
     */
    document.body.style.overflow = overflow;
    document.body.scrollTop = scrollTop;
  }

  updateHeaderWidth() {
    if (!this.refs.closeBtn || !this.refs.header) {
      return;
    }

    const { width, paddingRight } = window.getComputedStyle(
      this.refs.header,
      null
    );
    const rightPadding = parseInt(paddingRight, 10);
    const closeWidth = this.refs.closeBtn
      ? parseInt(this.refs.closeBtn.offsetWidth, 10)
      : 0;
    const sidePadding = closeWidth
      ? rightPadding * 2 + closeWidth
      : rightPadding;

    this.setState({
      sidePadding: `${sidePadding}px`,
      titleWidth: `${parseInt(width, 10) - sidePadding * 2}px`,
    });
  }

  calculateDimensions() {
    /*
     *  Compute the positioning values
     */
    const dialogNode = this.dialog || {};

    /*
     *  Props setting width and height put into variables named
     *  "maxWidth" and "maxHeight"
     */
    const {
      width: maxWidth = 0,
      height: maxHeight = 0,
    } = getMaximumWindowSize();

    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    const minWidth = Math.min(
      this.props.width || dialogNode.scrollWidth,
      maxWidth
    );
    const minHeight = Math.min(
      this.props.height || dialogNode.scrollHeight,
      maxHeight
    );

    const left = (windowWidth - minWidth) / 2;
    const top = (windowHeight - minHeight) / 2;

    return {
      left,
      top,
      maxWidth,
      maxHeight,
      minWidth,
      minHeight,
    };
  }

  positionToFitInside() {
    /*
     *  Calling "setState()" will trigger a call to "render()" where these values
     *  will be applied to the dialog
     */
    this.setState({
      ...this.calculateDimensions(),
      width: null,
    });
  }

  positionToFillWidth() {
    /*
     *  Set the state of the dialog with to the window width,
     *  triggering a "render()"
     */
    this.setState({
      left: null,
      top: null,
      maxWidth: null,
      maxHeight: null,
      minHeight: null,
      minWidth: null,
      width: window.innerWidth,
    });
  }

  hasMobileDimensions() {
    return this.props.maximiseOnMobile && window.innerWidth < MIN_WIDTH_SM;
  }

  repositionDialog = () => {
    // bound
    if (this.hasMobileDimensions()) {
      this.positionToFillWidth();
    } else {
      this.positionToFitInside();
    }

    if (this.state.header) {
      this.updateHeaderWidth();
    }
  };

  handleClickOutside = () => {
    // bound
    if (this.props.onClose && typeof this.props.onClose === 'function') {
      this.props.onClose();
    }
  };

  handleKeyPressed = event => {
    if (event.keyCode === KEY_CODE_ESCAPE) {
      this.handleClickOutside();
    }
  };

  scrollTo = to => {
    scrollElementTo(this.contentEl, to);
  };

  CloseButton() {
    if (this.props.noCloseButton) {
      return null;
    }
    if (this.props.closeText) {
      return (
        <div className={styles['close-text']} onClick={this.handleClickOutside}>
          {this.context.i18n('common.popup.close-button')}
        </div>
      );
    }
    return <div className={styles.close} onClick={this.handleClickOutside} />;
  }

  BackButton() {
    if (!this.props.maximiseOnMobile) {
      return null;
    }

    return (
      <div
        className={clsx(styles['close-text'], styles['back-text'])}
        onClick={this.handleClickOutside}
      >
        {this.context.i18n('common.popup.back-button')}
      </div>
    );
  }

  Header() {
    const HeaderCloseButton = this.CloseButton.bind(this);
    const HeaderBackButton = this.BackButton.bind(this);
    const mobileTitle = this.props.mobileTitle
      ? this.props.mobileTitle
      : this.props.title;
    const headerClasses = clsx(styles.title, {
      [styles.alertTitle]: this.props.alert,
    });

    return (
      <div className={styles.header}>
        <HeaderBackButton />
        <div
          className={headerClasses}
          style={{
            width: this.state.titleWidth,
          }}
        >
          <span className={styles['full-title']}>{this.props.title}</span>
          <span className={styles['mobile-title']}>{mobileTitle}</span>
        </div>
        <HeaderCloseButton />
      </div>
    );
  }

  render() {
    const { children, sizeContent, maximiseOnMobile, footer } = this.props;
    const CloseButton = this.CloseButton.bind(this);
    const Header = this.Header.bind(this);

    const className = clsx(styles.popup, {
      [styles.sizeFill]: !sizeContent,
      [styles.maximiseOnMobile]: maximiseOnMobile,
    });

    let style;
    let contentStyle;

    /**
     *  I think on first glance this looks more complicated than it is. We
     *  have defined the variables "style" and "contentStyle" and now we're
     *  going to populate them according to which view the user is in. Most
     *  of the values are pulled out of state because they've already been
     *  calculated
     */
    if (this.hasMobileDimensions()) {
      /**
       *  We're probably in Mobile view, so set the style attribute accordingly
       */
      const { width } = this.state;

      style = sizeContent
        ? {
            width: `${width}px`,
          }
        : null;
    } else {
      /**
       *  We're probably in Desktop view
       */
      const {
        left,
        top,
        maxWidth,
        maxHeight,
        minHeight,
        minWidth,
      } = this.state;

      /**
       *  Notice that "maxHeight" is being assigned the value of "minHeight"
       *  SQ does not know when this was introduced but believes it to be correct
       */
      style = sizeContent
        ? {
            left: `${left}px`,
            top: `${top}px`,
            maxWidth: `${maxWidth}px`,
            maxHeight: `${minHeight}px`,
            minWidth: `${minWidth}px`,
          }
        : null;

      /**
       *  Now notice that "maxHeight" is being calculated with. See previous
       *  comment :)
       */
      contentStyle = sizeContent
        ? {
            maxHeight: `${Math.max(
              0,
              (maxHeight || 0) - (this.props.header ? HEADER_SIZE : 0)
            )}px`,
          }
        : null;
    }

    return (
      <div>
        <div
          ref={ref => {
            this.dialog = ref;
          }}
          className={className}
          style={style}
        >
          {this.props.header ? <Header /> : <CloseButton />}
          <div
            ref={el => {
              this.contentEl = el;
            }}
            className={styles.content}
            style={contentStyle}
          >
            {typeof children === 'function'
              ? children({ scrollToFn: this.scrollTo })
              : children}
          </div>
          {footer}
        </div>
        <PageOverlay onClick={this.handleClickOutside} />
      </div>
    );
  }
}

export default Popup;
