import { Stack, Text } from '@treatwell/ui';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { useGrowthBook } from '@growthbook/growthbook-react';
import styles from './ExperimentAISummary.module.css';

export const ExperimentAISummary = ({
  reviewSummary,
}: {
  reviewSummary?: string;
}) => {
  const { t } = useTranslation();
  const gb = useGrowthBook();

  let isAISummaryExperiment: undefined | boolean;
  if (reviewSummary) {
    isAISummaryExperiment =
      gb.getFeatureValue('ab-web-venue__ai-review-summary', 'none') ===
      'variant-1';
  }

  if (!isAISummaryExperiment) {
    return null;
  }

  return (
    <Stack
      data-cy="ReviewsSummary"
      space="xs"
      className={styles.reviewsSummary}
    >
      <Text type="mdHeader">{t('venuePage.reviewsExperiment.title')}</Text>
      <Text>{reviewSummary}</Text>
      <Text type="footnote" className={styles.aiGenerated}>
        {t('venuePage.reviewsExperiment.footnote')}
      </Text>
    </Stack>
  );
};
