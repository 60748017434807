import React from 'react';
import { connect } from 'react-redux';
import { ReduxState } from 'js/model/state';
import { AccountDetailsState } from 'js/model/state/account/details';
import { LayoutConfig } from 'js/model/state/layout';

import { getLayoutConfig } from 'js/redux-modules/layout/selectors';
import { layoutHeaderBackClick } from 'js/redux-modules/layout/actions';

import { getAccountDetails } from 'js/redux-modules/account/selectors';
import { loadAccountDetails } from 'js/redux-modules/account/details/actions';

import { useIsMounted } from 'js/hooks/useIsMounted';
import { Header } from './Header';
import { AccountHeaderContainer } from './AccountHeader/AccountHeaderContainer';
import { SiteWideBanner } from '../SiteWideBanner/SiteWideBanner';
import { Context } from '../LocaleWrapper';
import { HeaderCmsOutput } from './HeaderCmsOutput';
import { getHeaderLinks } from './getHeaderLinks';

interface Props {
  accountDetails: AccountDetailsState;
  layoutConfig?: LayoutConfig;
  pageType: string;
  loadAccountDetails: () => unknown;
  layoutHeaderBackClick: () => unknown;
}

export function HeaderWrapper(props: Props) {
  const {
    accountDetails,
    layoutConfig,
    loadAccountDetails,
    layoutHeaderBackClick,
  } = props;
  const { i18n, generateUri, pageData } = React.useContext(Context);

  React.useEffect(() => {
    loadAccountDetails();
  }, []);

  const isBrowser = useIsMounted();

  function showAccountHeader(): boolean {
    const showBackButton = layoutConfig?.header?.backButton?.show ?? false;
    const logoCenter = layoutConfig?.header?.logo?.position === 'center';
    return showBackButton || logoCenter;
  }

  function renderContent(): React.ReactNode {
    const pageType = props.pageType as keyof typeof pageData;

    if (showAccountHeader()) {
      return (
        <AccountHeaderContainer
          onBackClick={layoutHeaderBackClick}
          layoutConfig={layoutConfig}
        />
      );
    }

    const { user } = accountDetails;
    let isAuthenticated = null;
    let isConnectAccount = false;
    if (user) {
      isAuthenticated = user.isAuthenticated;
      isConnectAccount = user.isConnectAccount;
    }
    const alternateLocaleUris =
      (pageData[pageType] && pageData[pageType].alternateLocaleUris) || [];
    const navigation = (i18n('header') as unknown) as HeaderCmsOutput;
    const headerLinks = getHeaderLinks({
      isBrowser,
      i18n,
      generateUri,
      pageData,
    });

    return (
      <Header
        isAuthenticated={isAuthenticated}
        isConnectAccount={isConnectAccount}
        channel={pageData.channel}
        alternateLocaleUris={alternateLocaleUris}
        navigation={navigation}
        headerLinks={headerLinks}
      />
    );
  }

  if (layoutConfig && layoutConfig.header && layoutConfig.header.hide) {
    return null;
  }

  return (
    <div id="header">
      {renderContent()}
      {isBrowser && (
        <SiteWideBanner
          cms={pageData.cms.common['site-wide-banner']}
          pageType={props.pageType}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state: ReduxState) => ({
  accountDetails: getAccountDetails(state),
  layoutConfig: getLayoutConfig(state),
});

const mapDispatchToProps = {
  loadAccountDetails,
  layoutHeaderBackClick,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderWrapper);
