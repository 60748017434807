import React from 'react';
import styles from './NewsletterSubscribe.module.css';
import CloseButton from './CloseButton';
import TextInput from './TextInput';
import TwSelect from '../form/Select';
import Button from '../Button';
import Checkbox from './Checkbox';
import AssetSuccessCheckbox from './AssetSuccessCheckbox';

const SUBSCRIPTION_SOURCE = 'EF';
const SOURCE_TAG = 'marketing_popup';

export default class NewsletterSubscribe extends React.Component {
  static EnumView = {
    signup: 0,
    success: 1,
    failure: 2,
  };

  static defaultProps = {
    showPostcodeInput: true,
    activeView: NewsletterSubscribe.EnumView.signup,
  };

  constructor(props) {
    super(props);

    this.state = {
      doLiveValidation: false,
      minHeight: null,
    };
  }

  componentDidMount() {
    this.hasStartedSubmission = false;
    this.measureAndUpdateMinHeight();

    window.addEventListener('resize', this.onResize);
  }

  // eslint-disable-next-line no-unused-vars
  // componentDidUpdate(prevProps) {
  //   if (ObjectHelper.isDeepEqualSimple(prevProps, this.props)) {
  //     return;
  //   }

  //   this.measureAndUpdateMinHeight();
  // }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps) {
    if (
      this.props.activeView === NewsletterSubscribe.EnumView.signup &&
      prevProps.activeView !== NewsletterSubscribe.EnumView.signup
    ) {
      this.hasStartedSubmission = false;
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  checkboxComponent = null;

  contentMeasureContainer = null;

  nameInput = null;

  emailInput = null;

  postcodeInput = null;

  genderSelect = null;

  hasStartedSubmission = false;

  onResize = () => {
    this.measureAndUpdateMinHeight();
  };

  onSubmitButtonClick = () => {
    if (this.hasStartedSubmission) {
      return;
    }

    if (
      !this.checkboxComponent ||
      !this.nameInput ||
      !this.emailInput ||
      !this.genderSelect
    ) {
      console.error('could not access refs');
      return;
    }

    this.setState({ doLiveValidation: true });

    if (!this.checkboxComponent.state.isActive) {
      this.checkboxComponent.shake();
      return;
    }

    if (!this.emailInput.state.isValid) {
      return;
    }

    // start submission
    this.hasStartedSubmission = true;
    this.setState({ doLiveValidation: false });

    this.props.onSubmit({
      fullName: this.nameInput.state.value,
      email: this.emailInput.state.value,
      postCode: this.postcodeInput ? this.postcodeInput.state.value : null,
      gender: this.getGenderSelectValue(),
      source: SUBSCRIPTION_SOURCE,
      sourceTag: SOURCE_TAG,
    });
  };

  getGenderSelectValue() {
    switch (this.genderSelect.state.value) {
      case undefined:
        return null;
      default:
        return this.genderSelect.state.value.value;
    }
  }

  validateEmail(str) {
    return str.match(/^\S+@\S+\.\S{2,}$/) !== null;
  }

  measureAndUpdateMinHeight() {
    if (!this.contentMeasureContainer) {
      return;
    }

    if (this.props.activeView !== NewsletterSubscribe.EnumView.signup) {
      return;
    }

    const minHeight = this.contentMeasureContainer.clientHeight;
    this.setState({ minHeight });
  }

  renderMessageTuple(text, showCheckboxAsset) {
    return [
      <div className={styles.success} key="success-newsletter">
        {showCheckboxAsset && (
          <AssetSuccessCheckbox
            positioningClassName={styles.successCheckbox}
            color={this.props.textColor}
          />
        )}
        <p
          className={styles.successText}
          style={{ color: this.props.textColor }}
        >
          {text}
        </p>
      </div>,
      <Button
        key="newsletter-button"
        className={styles.button}
        label={this.props.cmsItems.buttonClose}
        style={{
          backgroundColor: this.props.controlsBackgroundColor,
          color: this.props.controlsForegroundColor,
        }}
        onClick={this.props.onClose}
      />,
    ];
  }

  renderFormTuple() {
    const cms = this.props.cmsItems;

    const options = [
      { value: 'FEMALE', label: cms.genderFemale },
      { value: 'MALE', label: cms.genderMale },
    ];

    return [
      <React.Fragment key="form-newsletter">
        <div className={styles.form}>
          <TextInput
            ref={ref => {
              this.nameInput = ref;
            }}
            positioningClassName={styles.field}
            name="name"
            placeholder={cms.placeholderName}
            maxLength={100}
          />
          <TextInput
            ref={ref => {
              this.emailInput = ref;
            }}
            positioningClassName={styles.field}
            name="email"
            type="email"
            placeholder={cms.placeholderEmail}
            validationFunction={this.validateEmail}
            showValidation={this.state.doLiveValidation}
            maxLength={100}
          />
          <div className={styles.horizontalFieldsContainer}>
            {this.props.showPostcodeInput && (
              <TextInput
                ref={ref => {
                  this.postcodeInput = ref;
                }}
                positioningClassName={styles.field}
                name="postcode"
                type="text"
                placeholder={cms.placeholderPostcode}
                maxLength={10}
              />
            )}
            <TwSelect
              ref={ref => {
                this.genderSelect = ref;
              }}
              positioningClassName={styles.field}
              options={options}
              placeholder={cms.placeholderGender}
            />
          </div>
        </div>
        <Checkbox
          ref={ref => {
            this.checkboxComponent = ref;
          }}
          positioningClassName={styles.checkbox}
          textColor={this.props.textColor}
          controlsColor={this.props.controlsBackgroundColor}
          label={cms.textCheckbox}
          parensMatchHref={this.props.privacyUri}
        />
      </React.Fragment>,
      <Button
        key="newsletter-button"
        className={styles.button}
        onClick={this.onSubmitButtonClick}
        label={cms.buttonSubmit}
        style={{
          backgroundColor: this.props.controlsBackgroundColor,
          color: this.props.controlsForegroundColor,
        }}
      />,
    ];
  }

  render() {
    const cms = this.props.cmsItems;

    let view = null;
    let button = null;

    switch (this.props.activeView) {
      case NewsletterSubscribe.EnumView.signup:
        [view, button] = this.renderFormTuple();
        break;
      case NewsletterSubscribe.EnumView.success:
        [view, button] = this.renderMessageTuple(cms.textSuccess, true);
        break;
      case NewsletterSubscribe.EnumView.failure:
        [view, button] = this.renderMessageTuple(cms.textFailure, false);
        break;
      default:
        view = null;
        button = null;
        break;
    }

    return (
      <>
        <style>{`
          .${styles.container} {
            background-image: url("${encodeURI(cms.backgroundImageUrlMobile)}");

          }

          @media ${styles['bp-popup-desktop']} {
          .${styles.container} {
            background-image: url("${encodeURI(
              cms.backgroundImageUrlDesktop
            )}");
          }
        `}</style>

        <div className={styles.container} data-cy="newsletter-subscribe">
          <CloseButton
            positioningClassName={styles.closeButton}
            color={this.props.textColor}
            onClick={this.props.onClose}
          />
          <div
            className={styles.viewContainer}
            style={{ minHeight: this.state.minHeight }}
          >
            <div
              ref={ref => {
                this.contentMeasureContainer = ref;
              }}
            >
              <h1
                className={styles.ctaHeader}
                style={{ color: this.props.textColor }}
              >
                {cms.headerText}
              </h1>
              {view}
            </div>
          </div>
          {button}
        </div>
      </>
    );
  }
}
