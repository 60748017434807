import React from 'react';
import clsx from 'clsx';
import AssetCheckbox from './AssetCheckbox';
import styles from './Checkbox.module.css';
import * as DomHelper from './helpers/DomHelper';

export default class Checkbox extends React.Component {
  state = {
    isActive: false,
  };

  containerElement = null;

  onClick = () => {
    this.setState({ isActive: !this.state.isActive });
  };

  renderAnchoredLabel() {
    const match = this.props.label.match(/\{(.*?)\}/);

    if (!match) {
      return <React.Fragment>{this.props.label}</React.Fragment>;
    }

    return (
      <React.Fragment>
        {this.props.label.substr(0, match.index)}
        <a
          href={this.props.parensMatchHref}
          target="_blank"
          onClick={event => event.stopPropagation()}
          rel="noreferrer"
        >
          {match[1]}
        </a>
        {this.props.label.substr(match.index + match[0].length)}
      </React.Fragment>
    );
  }

  shake() {
    if (!this.containerElement) {
      return;
    }

    this.containerElement.classList.remove(styles.shake);
    DomHelper.forceLayout(this.containerElement);
    this.containerElement.classList.add(styles.shake);
  }

  render() {
    return (
      <div
        ref={ref => {
          this.containerElement = ref;
        }}
        className={clsx(styles.container, this.props.positioningClassName)}
        onClick={this.onClick}
      >
        <AssetCheckbox
          positioningClassName={styles.checkbox}
          isActive={this.state.isActive}
          primaryColor={this.props.textColor}
          secondaryColor={this.props.controlsColor}
        />
        <label className={styles.label} style={{ color: this.props.textColor }}>
          {this.renderAnchoredLabel()}
        </label>
      </div>
    );
  }
}
