import React from 'react';
import { createPortal } from 'react-dom';
import clsx from 'clsx';
import { KEY_CODE_ESCAPE } from 'js/constants/keycodes';
import { getDeviceTypeForWidth, MOBILE } from 'js/helpers/dom';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import styles from './Popup.module.css';

// If no height prop is provided (and so the content's natural height
// determines the popup height) this margin is used top and bottom to
// keep the popup away from the window edges.
const margin = 30;

interface Props {
  children: React.ReactNode;
  onCloseRequest?: (cause?: PopupCloseCause) => void;
  /**
   * width and height independently behave in the following ways:
   * - values larger then zero: create a Popup with absolute dimensions
   * - negative values and zero: create a Popup with the dimension of the window minus the value,
   *   basically creating a margin around the Popup of half the value
   * - undefined: auto-sizes the Popup according to its content
   *
   * In all cases width and height are constraint to not exceed the dimensions of the browser
   * window. Meaning that the Popup can never make the document scroll. Scrolling in the Popup
   * can be achieved by having its content overflow: scroll.
   */
  width?: number;

  /** If this height is not provided, we must make sure that children height is not going out of page */
  height?: number;

  /**
   * The preferred top position (relative to the window).
   *
   * It will be honoured as long as the popup fits. But if the bottom of the
   * popup (allowing for a margin) would extend beyond the bottom of the
   * window, then popup will be centred vertically.
   *
   * If a top position is not provided, then the popup will be centred vertically.
   */
  top?: number;

  left?: number;
  getHeight?: (height: number) => void;
}

interface DefaultProps {
  isAlwaysFullscreen: boolean;
  isFullscreenWhenOnMobileWidth: boolean;
  hasMarginWhenNotOnMobileWidth: boolean;
  hasRoundedCornersWhenNotOnMobileWidth: boolean;
  overlay: boolean | ((show: boolean) => void);
}

interface State {
  naturalHeight: number;
  windowInnerDimensions?: {
    width: number;
    height: number;
  };
  windowScrollY?: number;
}

export enum PopupCloseCause {
  ESCAPE_KEY,
  OVERLAY,
}

/*

  NOTE:

  Popup.tsx is not SSR-friendly, as it has client-side dependencies.
  Modifications must be made to allow for it to be SSR'd.

*/

export class Popup extends React.PureComponent<Props & DefaultProps, State> {
  public static readonly defaultProps: Readonly<DefaultProps> = {
    isAlwaysFullscreen: false,
    isFullscreenWhenOnMobileWidth: true,
    hasMarginWhenNotOnMobileWidth: false,
    hasRoundedCornersWhenNotOnMobileWidth: false,
    overlay: true,
  };

  private containerRef: React.RefObject<HTMLDivElement | null> = React.createRef();

  private contentRef: React.RefObject<HTMLDivElement | null> = React.createRef();

  private portalElement: HTMLElement = document.createElement('div');

  constructor(props: Props & DefaultProps) {
    super(props);

    this.state = {
      naturalHeight: 0,
      windowInnerDimensions: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    };

    document.body.appendChild(this.portalElement);

    this.enableDisableBodyScroll();
  }

  public getContainerHeight(): void {
    if (this.props.getHeight && this.containerRef.current) {
      this.props.getHeight(this.containerRef.current?.clientHeight);
    }
  }

  public componentDidMount(): void {
    this.onResize();
    window.addEventListener('resize', this.onResize);
    window.addEventListener('keydown', this.onKeyDown);

    this.handleExternalOverlay(true);
    this.updateWindowMeasurementState();

    // Note current window scroll position. Restored when umnmounted.
    this.setState({ windowScrollY: window.scrollY });
  }

  public componentWillUnmount(): void {
    window.removeEventListener('keydown', this.onKeyDown);
    window.removeEventListener('resize', this.onResize);

    document.body.removeChild(this.portalElement);

    this.enableBodyScroll();
    this.handleExternalOverlay(false);

    // Restore window scroll position to how it was on mount.
    window.scrollTo(0, this.state.windowScrollY!);
  }

  public componentDidUpdate(): void {
    this.updateNaturalHeight();
  }

  public updateNaturalHeight(): void {
    if (this.contentRef.current) {
      const naturalHeight = this.contentRef.current.getBoundingClientRect()
        .height;

      if (naturalHeight > this.state.naturalHeight) {
        this.setState({
          naturalHeight,
        });
      }
    }
  }

  private enableDisableBodyScroll = () => {
    if (!this.state.windowInnerDimensions || !this.containerRef.current) {
      return;
    }

    const isMobileWidth =
      getDeviceTypeForWidth(this.state.windowInnerDimensions.width) === MOBILE;
    if (isMobileWidth) {
      enableBodyScroll(this.containerRef.current);
    } else {
      disableBodyScroll(this.containerRef.current, {
        reserveScrollBarGap: true,
      });
    }
  };

  private enableBodyScroll = () => {
    if (!this.containerRef.current) {
      return;
    }

    enableBodyScroll(this.containerRef.current);
  };

  private handleExternalOverlay(show: boolean): void {
    if (typeof this.props.overlay === 'function') {
      this.props.overlay(show);
    }
  }

  private updateWindowMeasurementState(): void {
    this.setState(
      {
        windowInnerDimensions: {
          width: window.innerWidth,
          height: window.innerHeight,
        },
      },
      () => {
        this.enableDisableBodyScroll();
      }
    );

    this.updateNaturalHeight();
  }

  private onResize = (): void => {
    this.updateWindowMeasurementState();
    this.getContainerHeight();
  };

  private onPageOverlayClick = () => {
    this.dispatchCloseRequest(PopupCloseCause.OVERLAY);
  };

  private onKeyDown = (event: KeyboardEvent) => {
    if (event.keyCode === KEY_CODE_ESCAPE) {
      this.dispatchCloseRequest(PopupCloseCause.ESCAPE_KEY);
    }
  };

  public dispatchCloseRequest(cause: PopupCloseCause): void {
    if (this.props.onCloseRequest !== undefined) {
      this.props.onCloseRequest(cause);
    }
  }

  private static createDimensionCssValue(v: number | undefined): string {
    if (v === undefined) {
      return 'auto';
    }

    if (v > 0) {
      return `${v}px`;
    }
    return `calc(100% - ${Math.abs(v)}px)`;
  }

  public render() {
    let containerStyle: React.CSSProperties = {};
    let popupStyle: React.CSSProperties = {};
    let isFullScreenOnMobile = false;
    const {
      isAlwaysFullscreen,
      isFullscreenWhenOnMobileWidth,
      hasMarginWhenNotOnMobileWidth,
      hasRoundedCornersWhenNotOnMobileWidth,
    } = this.props;

    if (this.state.windowInnerDimensions !== undefined) {
      // we are on in the browser. first render should have matched server render
      const isMobileWidth =
        getDeviceTypeForWidth(this.state.windowInnerDimensions.width) ===
        MOBILE;

      if (isMobileWidth || isAlwaysFullscreen) {
        /**
         * We have to actively monitor and resize our divs because iOS Safari uses incorrect heights
         * when positioning via css. This is due to a trick they employ, to not change the window height
         * when displaying a toolbar at the bottom of the screen. Unfortunately this toolbar would be placed
         * over content that is positioned absolutely or fixed to the bottom of the page. By using window.innerHeight
         * we are aware when the toolbar is visible and can adjust our popup container accordingly.
         */
        containerStyle = {
          width: `${this.state.windowInnerDimensions.width}px`,
          height: `${this.state.windowInnerDimensions.height}px`,
        };

        if (this.props.top) {
          containerStyle.top = '0';
        }

        if (this.props.left) {
          containerStyle.left = '0';
        }
      }

      let { top, height } = this.props;
      if (isMobileWidth || isAlwaysFullscreen) {
        if (isFullscreenWhenOnMobileWidth || isAlwaysFullscreen) {
          popupStyle = containerStyle;
          isFullScreenOnMobile = true;
        } else {
          containerStyle = {
            width: 'calc(100% - 32px)',
            height: 'auto',
            margin: '32px 16px 0px 16px',
          };
        }
      } else {
        if (height === undefined) {
          // Use popup's natural height, if it will fit.

          if (top === undefined) {
            // Centre popup vertically in window.

            const max = this.state.windowInnerDimensions.height - margin * 2;
            if (this.state.naturalHeight >= max) {
              // Popup won't fit, so constrain its height.
              height = max;
            }
          } else {
            // Max height if honouring preferred top.
            const max = this.state.windowInnerDimensions.height - top - margin;

            if (this.state.naturalHeight >= max) {
              // Popup won't fit with preferred top, so try its natural height centred vertically.
              height = undefined;
              const max = this.state.windowInnerDimensions.height - margin * 2;
              if (this.state.naturalHeight >= max) {
                // Popup won't fit, so constrain its height.
                height = max;
              }

              // Don't use preferred top.
              top = undefined;
            }
          }
        } else {
          const max = this.state.windowInnerDimensions.height - margin * 2;
          if (height >= max) {
            // Popup won't fit, so constrain its height.
            height = max;
          }
        }

        popupStyle = {
          width: Popup.createDimensionCssValue(this.props.width),
          height: Popup.createDimensionCssValue(height),
        };

        if (hasMarginWhenNotOnMobileWidth) {
          popupStyle.margin = 24;
        }

        if (hasRoundedCornersWhenNotOnMobileWidth) {
          popupStyle.borderRadius = 4;
          popupStyle.overflow = 'hidden';
        }

        if (top) {
          popupStyle.top = `${this.props.top}px`;
        }

        if (this.props.left) {
          popupStyle.left = `${this.props.left}px`;
        }

        if (this.props.top || this.props.left) {
          popupStyle.position = 'absolute';
        }
      }
    }

    const fullPageOverlay = this.props.overlay === true;

    const overlayClasses = clsx(
      styles.overlay,
      { [styles.fullOverlay]: fullPageOverlay },
      {
        [styles.fullscreen]:
          this.props.isAlwaysFullscreen || isFullScreenOnMobile,
      }
    );

    const popupClassName = clsx(styles.popupContainer, {
      [styles.fullscreen]:
        this.props.isAlwaysFullscreen || isFullScreenOnMobile,
    });

    const containerClassName = clsx(styles.container, {
      [styles.fullscreen]: this.props.isAlwaysFullscreen,
    });

    return (
      <>
        {createPortal(
          <>
            <div className={overlayClasses} onClick={this.onPageOverlayClick} />
            <div
              className={containerClassName}
              style={containerStyle}
              ref={this.containerRef}
            >
              <div
                className={popupClassName}
                style={popupStyle}
                ref={this.contentRef}
              >
                {this.props.children}
              </div>
            </div>
          </>,
          this.portalElement
        )}
      </>
    );
  }
}
