import * as Sentry from '@sentry/browser';
import { captureConsoleIntegration } from '@sentry/integrations';
import { Dictionary, isDictionary } from 'js/types/generic-types';
import { isString } from 'js/types/primitive-predicates';
import {
  BROWSE_PAGE_REGEX,
  VENUE_PAGE_REGEX,
} from 'server/routes/page/constants';
import { getSentryOptions } from 'server/sentry-options';
import { NEAR_ME_PAGE_REGEX } from 'server/routes/page/near-me/constants';

const viewThroughConversionErrorExists = (event: Sentry.Event) => {
  return event?.exception?.values?.some(value => {
    return value.stacktrace?.frames?.some(frame => {
      return frame.filename?.includes('/pagead/viewthroughconversion');
    });
  });
};

export function configureSentry(): void {
  const state: Dictionary<unknown> | undefined =
    typeof window === 'object' && isDictionary(window.__state__)
      ? window.__state__
      : undefined;

  Sentry.init(
    getSentryOptions<Sentry.BrowserOptions>({
      allowUrls: [/https?:\/\/((cdn1|www)\.)?treatwell\./],
      ignoreErrors: [
        'Non-Error promise rejection captured',
        'TreatwellCookieConsent was undefined, this script depends on it',
        /GSI_LOGGER/,
      ],
      beforeSend: event => {
        if (viewThroughConversionErrorExists(event)) {
          return null;
        }
        return event;
      },
      release: isString(state?.version) ? state?.version : undefined,
      integrations: [
        captureConsoleIntegration({ levels: ['error'] }),
        Sentry.browserTracingIntegration({
          enableInp: true,
          beforeStartSpan(context) {
            const page = [
              { regex: BROWSE_PAGE_REGEX, transactionName: '/places' },
              { regex: NEAR_ME_PAGE_REGEX, transactionName: '/near-me' },
              { regex: VENUE_PAGE_REGEX, transactionName: '/place' },
            ].find(({ regex }) => regex.test(context.name));

            return {
              ...context,
              name: page?.transactionName ?? context.name,
            };
          },
        }),
      ],
      attachStacktrace: true,
      tracesSampler: samplingContext => samplingContext.parentSampled || false,
      tunnel: '/sentry-tunnel',
    })
  );

  if (isString(state?.ruid)) {
    Sentry.getCurrentScope().setTag('ruid', state.ruid);
  }
}
