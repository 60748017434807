import React from 'react';
import { MarketplaceSelector } from 'js/components/MarketplaceSelector/MarketplaceSelector';
import { NewsletterPopup } from 'js/components/NewsletterPopup/NewsletterPopup';
import { MarketplaceOutput } from 'js/model/rainbow/content/MarketplaceOutput';
import {
  CmsMarketplaceFooterLink,
  CmsMarketplaceFooterLinkGroups,
  SocialMediaIcon,
} from 'js/model/cms/cms-footer';
import clsx from 'clsx';
import { Context } from '../LocaleWrapper';
import styles from './styles.module.css';

const OPEN_NEWSLETTER_POPUP = 'OPEN_NEWSLETTER_POPUP';

interface Props {
  ruid?: string;
  channelCode: string;
  marketplaces: MarketplaceOutput;
  layoutConfig: {
    footer?: {
      hide?: boolean;
    };
  };
  showCookieSettingsLinks: boolean;
}

export function Footer(props: Props) {
  const [showNewsletterPopup, setShowNewsletterPopup] = React.useState<boolean>(
    false
  );

  const { i18n, generateUri, pageData } = React.useContext(Context);

  React.useEffect(() => {
    window.openNewsletterSubscribePopup = () =>
      handleAction(OPEN_NEWSLETTER_POPUP);
  }, []);

  function handleActionClick(
    event: React.MouseEvent<HTMLAnchorElement>,
    action: string
  ): void {
    event.preventDefault();
    return handleAction(action);
  }

  function handleAction(action: string): void {
    if (action === OPEN_NEWSLETTER_POPUP) {
      setShowNewsletterPopup(true);
    }
  }

  function generateListLinks(
    cookieSettingsLabel: string,
    groupIndex: number,
    items: CmsMarketplaceFooterLink[]
  ): React.ReactNode {
    return items.map((item, index) => {
      const img = item['image-uri'];
      const imgHtml = img ? <img src={img} alt={item.title} /> : null;
      const linkText = item.text;
      const uri = item.uri || '';
      const action = item.action;

      if (!linkText && !imgHtml) {
        return null;
      }

      const linkNode = (
        <a
          key={index}
          href={uri.trim()}
          onClick={action ? e => handleActionClick(e, action) : undefined}
          className={styles.listItemLink}
          title={item.title}
        >
          {linkText}
          {imgHtml}
        </a>
      );

      return (
        <React.Fragment key={index}>
          <li className={styles.linkItem}>{linkNode}</li>

          {groupIndex === 3 && index === 2 && props.showCookieSettingsLinks && (
            <li className={styles.linkItem}>
              <button
                className={styles.listItemButton}
                title={cookieSettingsLabel}
                onClick={event => {
                  event.preventDefault();
                  if (!window.TreatwellCookieConsent) {
                    return;
                  }
                  window.TreatwellCookieConsent().then(api => {
                    api.openPreferences();
                  });
                }}
              >
                {cookieSettingsLabel}
              </button>
            </li>
          )}
        </React.Fragment>
      );
    });
  }

  function generateListLinkGroups(
    cookieSettingsLabel: string | undefined,
    itemsGroup: CmsMarketplaceFooterLinkGroups[]
  ): React.ReactNode {
    if (cookieSettingsLabel === undefined) {
      return null;
    }
    return itemsGroup.map((items, index) => (
      <div className={styles.linkGroup} key={`footer-list-link-group-${index}`}>
        <div className={styles['link-group-title']}>{items.name}</div>
        <ul className={styles['link-list']} key={index}>
          {generateListLinks(cookieSettingsLabel, index, items.links)}
        </ul>
      </div>
    ));
  }

  function generateSocialMediaBlock(
    socialLinks: SocialMediaIcon[]
  ): React.ReactNode {
    return socialLinks.map((item, index) => (
      <a
        key={index}
        className={clsx(styles['social-media-icon'], styles[item.name])}
        href={item.uri}
        title={item.text}
      />
    ));
  }

  function handlePopupClose(): void {
    setShowNewsletterPopup(false);
  }

  function renderNewsletterPopup(): React.ReactNode {
    return (
      <NewsletterPopup
        i18n={i18n}
        generateUri={generateUri}
        channelCode={channelCode}
        requestHeaders={pageData.requestHeaders}
        onClose={handlePopupClose}
      />
    );
  }

  const { ruid, channelCode, marketplaces, layoutConfig } = props;
  if (layoutConfig.footer?.hide) {
    return null;
  }

  const newsletterPopup = showNewsletterPopup ? renderNewsletterPopup() : null;
  const date = new Date();

  return (
    <div className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          {generateListLinkGroups(
            pageData.cms.footer['link-groups'][3]['cookie-settings'] as string,
            pageData.cms.footer[
              'link-groups'
            ] as CmsMarketplaceFooterLinkGroups[]
          )}
        </div>
        <div className={styles['footer-bottom']}>
          <div className={styles.socialMediaIcons}>
            {generateSocialMediaBlock(
              pageData.cms.footer['social-media-icons'] as SocialMediaIcon[]
            )}
          </div>
          <MarketplaceSelector
            marketplaces={marketplaces}
            channelCode={channelCode}
          />
        </div>
        <div
          id="footer-copyright"
          className={styles.copyright}
          suppressHydrationWarning
        >
          {`© ${date.getFullYear()} ${i18n('footer.copyright')}`}
        </div>

        <div className={styles.ruid}>{ruid}</div>
      </div>
      {newsletterPopup}
    </div>
  );
}
