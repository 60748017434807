/* eslint no-underscore-dangle: 0 */

/*
 * app-clientside.js is the starting point for browser rendering
 * The server does not render this file.
 */

import './force-css-load-order';

/* Include core js polyfill for browser rendering */
import 'core-js/stable';
import React from 'react';
import { hydrateRoot } from 'react-dom/client';

import { removeClonedInfoColumnContainers } from 'js/components/InfoColumnBlocks/clone';
import storeHideEmailOptIn from 'js/helpers/store-hide-email-opt-in';

import {
  DeviceProvider,
  FeatureFlagProvider,
  getFeatureFlagsFromCookieString,
  ToastProvider,
  ToastPortal,
} from '@treatwell/ui';
import { UrlProvider } from 'js/components/url';
import { loadComponent } from 'js/pages/async-component';
import { createStore } from 'js/store/createStore';
import i18n from 'i18next';
import { Provider } from 'react-redux';
import { useSSR, initReactI18next } from 'react-i18next';
import { HelmetProvider } from 'react-helmet-async';
import { GrowthBookProvider } from '@growthbook/growthbook-react';
import { CookieProvider } from './components/Loyalty/LoyaltyBanner/CookieProvider';
import { documentReady } from './helpers/ready';
import Root from './root';
import { initialiseCoreLibraries } from './initialise-core-libs';
import ExperimentDisplayGoogleOneTap from './components/ExperimentDisplayGoogleOneTap';

const store = createStore(window.__state__);
const container = document.getElementById('container');
const componentLoaded = loadComponent(window.__state__.pageType);

function InitialiseI18Next({ children, initialLanguage, initialI18nStore }) {
  useSSR(initialI18nStore, initialLanguage);
  return children;
}

async function render() {
  // Remove all manipulated info column containers, and show the cloned containers.
  // This should re-instate the DOM that react expects, and avoid complaints from React.
  removeClonedInfoColumnContainers();

  // wait for some important things to load before hydrating the app
  const [growthbook] = await Promise.all([
    initialiseCoreLibraries(),
    componentLoaded,
    i18n.use(initReactI18next).init({
      ...window.twI18nConfig.options,
      lng: window.twI18nConfig.initialLanguage,
      compatibilityJSON: 'v3',
    }),
  ]);

  hydrateRoot(
    container,
    <HelmetProvider>
      <UrlProvider
        url={window.location.href}
        channel={window.__state__.channel}
      >
        <GrowthBookProvider growthbook={growthbook}>
          <DeviceProvider userAgent={navigator.userAgent}>
            <CookieProvider cookies={window.document.cookie}>
              <FeatureFlagProvider
                features={getFeatureFlagsFromCookieString(document.cookie)}
              >
                <Provider store={store}>
                  <InitialiseI18Next
                    initialI18nStore={window.twI18nConfig.initialI18nStore}
                    initialLanguage={window.twI18nConfig.initialLanguage}
                  >
                    <ToastProvider>
                      <Root pageData={window.__state__} />
                      <ToastPortal root="container" />
                      <ExperimentDisplayGoogleOneTap
                        pageData={window.__state__}
                      />
                    </ToastProvider>
                  </InitialiseI18Next>
                </Provider>
              </FeatureFlagProvider>
            </CookieProvider>
          </DeviceProvider>
        </GrowthBookProvider>
      </UrlProvider>
    </HelmetProvider>
  );
}

documentReady(() => {
  storeHideEmailOptIn(window.__state__.channel.type === 'widget');
  render();
});
